<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    imgLink?: string
    dir?: string
  }>(),
  {
    imgLink: 'https://placehold.co/1200x600',
    dir: 'left',
  },
)
</script>

<template>
  <div class="w-full bg-white">
    <div class="relative h-110 overflow-hidden rounded-lg">
      <div class="absolute inset-0">
        <img :src="props.imgLink" :alt="props.imgLink" class="h-full w-full object-cover object-center">
      </div>
      <div aria-hidden="true" class="relative h-96 w-full lg:hidden" />
      <div aria-hidden="true" class="relative h-32 w-full lg:hidden" />
      <div class="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-55 p-6 backdrop-blur backdrop-filter lg:inset-x-auto lg:inset-y-0 lg:w-96 sm:flex lg:flex-col lg:items-start sm:items-center sm:justify-between lg:rounded-br-none lg:rounded-tl-lg">
        <div class="h55 md:h-100">
          <slot name="content">
            <h2 class="text-lg text-white">
              There should
            </h2>
            <h2 class="text-lg text-white">
              be content use #content slot
            </h2>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
